import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";
import Quote from "../../components/quote";
import AnalyticsReports from "../../icons/analytics-reports.svg";
import Communicate from "../../icons/communicate.svg";
import MarketResearch from "../../icons/market-research.svg";
import Personas from "../../icons/personas.svg";
import UiUxSetup from "../../icons/ui-ux-setup.svg";
import UserFlowUserJourner from "../../icons/user-flow-user-journey.svg";

const PortfolioHeader = () => (
  <section className="bg-gray-700 py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">Sundance Institute</p>
            </div>
            <div className="col-12 mt-0 mt-lg-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">UI/UX Services</p>
              <p className="text-white">Website Design Consulting Service</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mt-5 mt-lg-0">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white mb-0">
            729 SOLUTIONS SUPERCHARGED THE USER EXPERIENCE FOR THE POPULAR
            SUNDANCE INSTITUTE WEBSITE.
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const LaptopImage = () => (
  <section>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <StaticImage
            src="../../images/portfolio/sundance/laptop-hero.png"
            alt="Sundance Mockups"
          />
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2>Problem Statement</h2>
              <p>
                Sundance Institute needed a UX consultant who could improve the
                usability of their site and provide a smooth interface for film
                lovers and festival attendees.
              </p>
              <p>
                They also needed a web design consultant to create a website
                experience that matched their valuable brand identity and
                provided a memorable online experience.
              </p>
            </div>
            <div className="col-12 mt-6 mt-lg-0">
              <h2>On-Site with Sundance</h2>
            </div>
            <div className="col-12 col-lg-6">
              <div className="row">
                <div className="col-12 mb-5 mb-lg-0">
                  <StaticImage
                    src="../../images/portfolio/sundance/julio-and-nikki.jpg"
                    alt="Julio and Nikki"
                  />
                </div>
                <div className="col-12">
                  <small className="text-muted">729 Team at Sundance</small>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <StaticImage
                    src="../../images/portfolio/sundance/julio-interviewing.jpg"
                    alt="Julio and Nikki"
                  />
                </div>
                <div className="col-12">
                  <small className="text-muted">Internal Interviews</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <div className="row">
            <div className="col-12">
              <h2>Project Goals</h2>
              <ol className="text-primary">
                <li>
                  <span className="text-dark">
                    Establish and document the project requirements.
                  </span>
                </li>
                <li>
                  <span className="text-dark">Interview the stakeholders.</span>
                </li>
                <li>
                  <span className="text-dark">
                    Recommend an optimal future site structure.
                  </span>
                </li>
                <li>
                  <span className="text-dark">Define user personas.</span>
                </li>
                <li>
                  <span className="text-dark">
                    Recommend optimizations for multiple user journeys.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Identify marketing opportunities.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Determine and perform user testing.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Create wireframes and prototypes.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Vastly improve the user experience.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Create an engaging site with year-round interest.
                  </span>
                </li>
                <li>
                  <span className="text-dark">Stay cost-efficient. </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Mockups = () => (
  <section>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <StaticImage
            src="../../images/portfolio/sundance/mockups.jpg"
            alt="Mobile Sundance Mockups"
          />
        </div>
      </div>
    </div>
  </section>
);

const HowWeDidIt = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Software Used</h2>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-xd.png"
                        alt="Adobe XD"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/bluejeans.jpg"
                        alt="Bluejeans"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/lucidchart.jpg"
                        alt="Lucid Chart"
                        width={50}
                        height={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">Development Languages Used</h2>
              <p className="mb-0">JavaScript</p>
              <p className="mb-0">HTML 5</p>
              <p className="mb-0">CSS 3</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">Frameworks Used</h2>
              <p className="mb-0">Intuit CSS</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Research Methods</h2>
              <p>
                This client was already quite familiar with user experience
                research and implementation, so we found ourselves working in
                perfect harmony with them as user experience consultants.
              </p>
              <div className="row align-items-center">
                <div className="col-2">
                  <Communicate color="gray" />
                </div>
                <div className="col-10">
                  <p>Customer Interviews</p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-2">
                  <div className="p-1">
                    <div style={{ border: "1px solid gray" }}>
                      <Communicate color="gray" />
                    </div>
                  </div>
                </div>
                <div className="col-10">Internal Stakeholder Interviews</div>
                <div className="col-2">
                  <MarketResearch color="gray" />
                </div>
                <div className="col-10">
                  <p>Market Research Analysis</p>
                </div>
                <div className="col-2">
                  <AnalyticsReports color="gray" />
                </div>
                <div className="col-10">
                  <p>Internal Business Analysis</p>
                </div>
                <div className="col-2">
                  <UiUxSetup color="gray" />
                </div>
                <div className="col-10">
                  <p>Prototype Based User Testing</p>
                </div>
                <div className="col-2">
                  <Personas color="gray" />
                </div>
                <div className="col-10">
                  <p>Persona Research</p>
                </div>
                <div className="col-2">
                  <UserFlowUserJourner color="gray" />
                </div>
                <div className="col-10">
                  <p>User Flow and Journey Mapping</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const UserFlow = () => (
  <section className="my-6">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h2>Workflow / User Journey</h2>
        </div>
        <div className="col-12 text-center">
          <StaticImage
            src="../../images/portfolio/sundance/userflow.jpg"
            alt="User Flow"
          />
        </div>
      </div>
    </div>
  </section>
);

const FocusAreasAndNeeds = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Key Focus Areas</h2>
          <p>What are the challenges or barriers within the industry?</p>
          <p>
            What are the internal challenges and barriers that Sundance faces?
          </p>
          <p>How do we address the persona type overlap?</p>
          <p>How do we appeal to a wide demographic of personas?</p>
          <p>How do we marry engagement goals with the educational mission?</p>
          <p>
            How can we make website maintenance quick and easy for internal
            teams?
          </p>
          <p>Where should the website leave off and the mobile app pick up?</p>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2>Core Client Needs</h2>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <p className="mb-0">
                <b>One-stop-shop</b>
              </p>
              <p>
                Sundance needed a UX consultant to serve as an all-in-one
                solution to their user experience challenges.
              </p>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <p className="mb-0">
                <b>Phased implementation</b>
              </p>
              <p>
                Sundance needed to roll out user experience features on a phased
                basis and not overwhelm internal or external customers.
              </p>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <p className="mb-0">
                <b>Belief in the cause</b>
              </p>
              <p>
                They needed UX consultants who believed in their cause, which is
                to serve artists around the world.
              </p>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <p className="mb-0">
                <b>Unbiased recommendations</b>
              </p>
              <p>
                A key feature they needed was unbiased recommendations regarding
                user experience for internal teams and external users.
              </p>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <p className="mb-0">
                <b>Easy collaboration</b>
              </p>
              <p>
                The client needed a user experience consultant who could
                collaborate seamlessly with their own technical team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Learnings = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Learnings</h2>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-lg-6">
              <p>
                Sundance had been through a light version of a UI/UX process in
                the past, so our primary contacts there knew how the process
                would work. We learned that these are our favorite kinds of
                clients! They understand the benefits of doing things at the
                right scale.
              </p>
              <p>
                The website design consulting process was easier than we
                expected because our Sundance contacts were savvy professionals
                who understood the benefits of doing everything correctly, right
                from the start. We saw them take their already-successful site
                to a completely new level by providing an intensely intuitive
                user experience that’s fun and memorable.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <p>
                729 Solutions developed this client’s user experience and web
                solutions by creating in-device features, mapping user flows,
                creating wireframes and mock-ups, doing persona builds and
                implementing a new web architecture. We supercharged the user
                experience and worked as a website design consultant to take
                this festival’s site to an entirely new level of usability and
                engagement.
              </p>
              <p>
                In addition, we developed a business requirements document (BRD)
                for them, which allowed us to really dig deeper into their
                business and understand it on a microscopic level. A BRD is a
                powerful document that can benefit any company – maybe even
                yours.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SundanceQuote = () => (
  <section className="mt-6 mb-0 bg-dark">
    <div className="container">
      <div className="row align-items-center p-5">
        <div className="col-12 col-lg-6">
          <Quote
            dark
            quote="729 took the time to understand more than just our UI/UX concerns - they explored our business goals as well. This has allowed us to make decisions that consider the larger, long term vision, in addition to solving the immediate needs we brought to the table."
            name="Kate Benay"
            company="Director of Marketing, Sundance"
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="p-6">
            <StaticImage
              style={{ width: "100%" }}
              src="../../images/portfolio/sundance/logo.png"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6 mt-0 mb-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location}/>
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Sundance Institute"/>
      <PortfolioHeader />
      <LaptopImage />
      <ProjectGoals />
      <Mockups />
      <HowWeDidIt />
      <UserFlow />
      <FocusAreasAndNeeds />
      <Learnings />
      <SundanceQuote />
      <ProjectForm location={location} />
      <PortfolioFeed />
    </Layout>
  );
};

export default Index;
